import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/5JHLMVbxyh0"
    bibleGroupSrc="https://pdfhost.io/v/Sspz.Vr93_Microsoft_Word_Bible_Group_Homework_110docx.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="The Story - If Not Us, Who?" />
  </Layout>
)

export default SermonPost
